html,
body {
    overflow: hidden;
    margin: 0;
    height: 100vh;
    min-height: 100vh;
}

#root {
    overflow: inherit;
    height: inherit;
    min-height: inherit;
}

.app {
    text-align: center;
    background-color: #282c34;
    color: white;
    height: inherit;
    min-height: inherit;
    display: flex;
    flex-direction: column;
}

.app-header,
.app-footer {
    width: 100vw;
    flex: none;
    background-color: yellowgreen;
}

.app-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5050;
}

.app-footer {
    position: fixed;
    bottom: 0;
    left: 0;
}

.app-footer.static {
    position: static;
}

.app-header-title,
.app-footer-content {
    font-size: inherit;
}

.app-container {
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-Content: space-between;
    overflow-y: auto;
    font-size: inherit;
    margin-top: var(--headerHeight);
    margin-bottom: var(--footerHeight);
}

.app-container-aux {
    display: flex; 
    height: -webkit-fill-available;
    width: -webkit-fill-available;
}

.app-container.scrolled {
    display: flex;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.app-container.contents {
    overflow-y: auto;
}

.app-container.static {
    overflow-y: inherit;
}

.app-container-content {
    display: flex;
    flex: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    font-size: calc(10px + 2vmin);
    color: white;
    height: inherit;
}

.app-container-content.scrolled {
    display: block;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.app-container-content.contents {
    overflow-y: inherit;
    background: lemonchiffon;
}

.app-logo {
    height: 40vmin;
    pointer-events: none;
}

.app-link {
    color: #61dafb!important;
    text-decoration: none!important;
}

.app-link:hover {
    text-decoration: underline;
}

button.button-link {
    background: none!important;
    border: none;
    padding: 0!important;
    font-family: arial, sans-serif;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
    font-size: inherit;
}

button.button-link:hover {
    text-decoration: underline;
}

button.button-link:disabled {
    text-decoration: none;
    color: #666666!important;
    cursor: default;
}

@media (prefers-reduced-motion: no-preference) {
    .app-logo {
        animation: app-logo-spin infinite 20s linear;
    }
}

@keyframes app-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Safari 7.1+ */
@media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) {
        .app-container-content {
            height: auto; /* Overrides height: -webkit-fill-available */
        }
    }
}

/* IE9+ (not working) */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .app-container-aux { 
        height: calc(100vh - 53px - 53px);
        color: red !important;
    }   
}